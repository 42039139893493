import Rails from "@rails/ujs";
import { Confirm } from "./confirm.js";

class UJSConfirmEvent extends Event {
  constructor({ element, response, askAgain }) {
    super("rails-ujs:confirm");
    Object.assign(this, { element, response, askAgain });
  }
}

/* This replaces Rails' confirm in-browser dialogs with something nicer */
const modalConfirm = (message, element) => {
  (async () => {
    let options = {};

    /* allow the modal to be configured by optional attributes */
    if (element.dataset.confirmYes) {
      options.confirm = element.dataset.confirmYes;
    }

    if (element.dataset.confirmNo) {
      options.cancel = element.dataset.confirmNo;
    }

    if (element.dataset.confirmHtml) {
      options.html = element.dataset.confirmHtml === "true";
    }

    if (element.dataset.confirmAskAgain) {
      options.askAgain = element.dataset.confirmAskAgain;
      if (options.askAgain === "true" || options.askAgain === "false") {
        options.askAgain = options.askAgain === "true";
      }
    }

    const result = await Confirm.confirm(message, options);

    if (result) {
      try {
        /* Temporarily set the predicate that always passes and re-raise */
        const alwaysTrue = () => true;
        Rails.confirm = alwaysTrue;

        /* Notify, and allow submission to be stopped */
        const ev = new UJSConfirmEvent({
          element,
          response: result.response,
          askAgain: result.askAgain,
        });

        document.dispatchEvent(ev);

        if (!ev.defaultPrevented) {
          element.click();
        }
      } finally {
        /* Re-set it to our replacement */
        Rails.confirm = modalConfirm;
      }
    }
  })();

  return false;
};

Rails.confirm = modalConfirm;
Rails.start();
