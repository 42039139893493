/* Inputs marked as:
 *
 *   <input ... data-auto-submit>
 *
 *  Automatically submit its containing form on "change" event.
 *
 * To specify other events (like "input"), set the "data-auto-submit" attribute to a
 * space-separated list.  "true" is interpreted as "change".
 *
 * The DOM is watched for mutations, so HTML coming from the server side
 * will be processed automatically.
 */

import { onceEach } from "./once.js";
import { onDateChange } from "./on_date_change.js";

const scan = (document) => {
  const selectors = ["input", "textarea", "select"]
    .map((tag) => `${tag}[data-auto-submit]`)
    .join(", ");

  onceEach(document.querySelectorAll(selectors), "auto-submit", (input) => {
    if (input.dataset.autoSubmit == "false") {
      return;
    }

    let eventNames = (input.dataset.autoSubmit || "").split(/\s+/).filter((k) => k && k !== "true");
    if (eventNames.length === 0) {
      eventNames = ["change"];
    }

    eventNames.forEach((eventName) => {
      const fire = () => {
        if (typeof input.form.requestSubmit === "function") {
          input.form.requestSubmit();
        } else {
          /* COMPAT: Safari < 16 */
          input.form.submit();
        }
      };

      if (input.type === "date" && eventName === "change") {
        onDateChange(input, fire);
      } else {
        input.addEventListener(eventName, fire);
      }
    });
  });
};

document.addEventListener("DOMContentLoaded", () => scan(document));
document.addEventListener("partial:replace", (ev) => scan(ev.container));
