/* Replace the boring browser alert with something styled. */
import { forceMobile } from "./force_mobile.js";

export const Confirm = {
  template: `
    <aside class="modal is-active">
      <div class="modal-background" data-cancels></div>
      <div class="modal-content">
        <div class="box">
          <div data-message></div>
          <footer class="mt-6">
            <hr>
            <div class="is-flex is-justify-content-space-between">
              <div data-ask-again class="is-flex is-align-items-center">
                <label class="checkbox">
                  <input type="checkbox">
                  <span>Don't ask again</span>
                </label>
              </div>
              <div>
                <button class="button" data-cancels>Cancel</button>
                <button class="button is-info ml-2" data-confirms>Confirm</button>
              </div>
            </footer>
          </div>
        </div>
      </div>
      <button class="modal-close is-large" aria-label="close" data-cancels></button>
    </aside>
  `,

  /* Allow optional text override or removing of buttons in the modal */
  configureButton({ modal, selector, value }) {
    if (value !== undefined) {
      const element = modal.querySelector(selector);
      if (!element) {
        return;
      }

      if (value === null || value === false) {
        element.remove();
      } else {
        element.innerText = value;
      }
    }
  },

  createModal(message, { cancel, confirm, askAgain, html = true } = {}) {
    const placeHolder = document.createElement("div");
    placeHolder.insertAdjacentHTML("afterbegin", Confirm.template);

    const modal = placeHolder.firstElementChild;
    const messageElement = modal.querySelector("[data-message]");

    if (html) {
      messageElement.classList.add("content");
      messageElement.insertAdjacentHTML("afterbegin", message);
    } else {
      messageElement.insertAdjacentText("afterbegin", message);
    }

    const askAgainElement = modal.querySelector("[data-ask-again]");
    if (askAgain) {
      if (typeof askAgain === "string") {
        askAgainElement.querySelector("span").innerText = askAgain;
      }
    } else {
      /* Keep the element so flex-end keeps buttons unaligned, but give it no content */
      askAgainElement.classList.add("is-invisible");
      askAgainElement.querySelectorAll("*").forEach((element) => element.remove());
    }

    this.configureButton({ modal: modal, selector: ".button[data-cancels]", value: cancel });
    this.configureButton({ modal: modal, selector: ".button[data-confirms]", value: confirm });

    return modal;
  },

  async showModal(modal) {
    const removeModal = () => {
      modal.remove();
      document.removeEventListener("keydown", keydownListener);
    };

    let dismissModal, confirmModal;

    const resultPromise = new Promise((resolve, reject) => {
      dismissModal = () => {
        removeModal();
        resolve(false);
      };

      confirmModal = () => {
        const result = {
          response: true,
          askAgain: modal.querySelector("[data-ask-again] input")?.checked,
        };
        removeModal();
        resolve(result);
      };
    });

    const keydownListener = document.addEventListener("keydown", (ev) => {
      if (ev.key == "Escape") {
        ev.preventDefault();
        dismissModal();
      }
    });

    modal.querySelectorAll("[data-cancels]").forEach((element) => {
      element.addEventListener("click", dismissModal);
    });

    const confirmButton = modal.querySelector("[data-confirms]");
    confirmButton.addEventListener("click", confirmModal);

    document.body.appendChild(modal);

    confirmButton.focus();

    return resultPromise;
  },

  async confirm(message, options = {}) {
    const modal = this.createModal(message, options);

    const mobileLock = forceMobile();
    const result = await this.showModal(modal);
    mobileLock.restore();
    return result;
  },
};
