import { onceEach } from "./once.js";
import tippy from "tippy.js";
import { isNil } from "lodash-es";

const scan = (document) => {
  onceEach(document.querySelectorAll("[data-hint], [data-hint-html]"), "hint", (e) => {
    const allowHTML = e.dataset.hintHtml !== undefined;
    const content = allowHTML ? e.dataset.hintHtml : e.dataset.hint;

    tippy(e, {
      content,
      allowHTML,

      // otherwise 'interactive' hints can end up small since they will be appended to parent
      appendTo: document.body,

      // allows clicking links
      interactive: !isNil(e.dataset.hintInteractive) && e.dataset.hintInteractive !== "false",
      trigger: "mouseenter focus click",
      theme: "recipal",
    });

    /* When used in sortable tables, clicking shouldn't sort */
    e.addEventListener("click", (ev) => {
      ev.stopPropagation();
      ev.preventDefault();
    });
  });
};

document.addEventListener("DOMContentLoaded", () => scan(document));
document.addEventListener("partial:replace", (ev) => scan(ev.container));
